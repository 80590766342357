import React, { useState } from 'react';
import { svgSprite, Div } from '../wrappers';
import getStyles from '../../project/styles/widget-styles/share-styles';
import Styles from '../../common/decorators/Styles';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    ViberShareButton,
    FacebookIcon,
    TwitterIcon,
    ViberIcon,
    WhatsappIcon,
    TelegramIcon
} from 'react-share';
import uikit from '../../project/uikit/styles';
import PropTypes from 'prop-types';

const SocialShare = (props) => {
    const {
        btnStyles, btnStylesHover, styles, val, btnTitle, positionDropdown
    } = props;
    const [shareModal, setShareModal] = useState(false);
    return (
        <>
            {shareModal &&
                <Div styles={styles.modalShareBodyBackground}
                    data-uitest='44_uitest' onClick={() => setShareModal(false)}>
                </Div>
            }
        <Div onPress={() => { setShareModal(true) }}
             data-uitest='45_uitest' onClick={() => { setShareModal(true) }}
             styles={styles.btnShare}
        >
            <Div
                styles={{


                    ...uikit.flexColumn, ...uikit.justifyContentCenter, ...btnStyles
                }}
                effects={{ hover: { opacity: '.5' } }}>
                {
                    svgSprite('share', {
                        width: '19px',
                        height: '20px',
                        fill: '#8F8FAB'
                    })
                }
            </Div>

            {btnTitle && <Div styles={{ ...styles.btnShareTitle }}>{btnTitle}</Div>}
                {
                    shareModal &&
                        <Div styles={{ ...styles.modalShareBody, ...positionDropdown==='left'?styles.modalShareBodyLeft:styles.modalShareBodyBottom }}
                            onMouseLeave={() => setShareModal(false)}
                        >
                            <Div styles={styles.modalShare}>
                                <Div styles={{ 'position': 'absolute', ...positionDropdown==='left'?styles.dropdownWhiteArrowLeft:styles.dropdownWhiteArrowBottom }}>
                                    {
                                        svgSprite('arrow-dropdown-right', {
                                            width: '14px',
                                            height: '36px',
                                            fill: styles.variable.whiteColor
                                        })
                                    }
                                </Div>
                                <Div effects={{ hover: { ...styles.modalShareItemHover } }} styles={{ ...styles.modalShareItemWrapper, ...styles.modalShareItemWrapperFirst }}>
                                    <FacebookShareButton url={val}>
                                        <Div styles={styles.modalShareItem}>
                                            <Div styles={styles.modalShareItemIcon}>
                                                <FacebookIcon
                                                    size={40}
                                                    iconFillColor={'rgba(72, 103, 170, 1)'}
                                                    bgStyle={{ 'fill': 'transparent' }}
                                                />
                                            </Div>
                                            <Div>Facebook</Div>
                                        </Div>
                                    </FacebookShareButton>
                                </Div>
                                <Div effects={{ hover: { ...styles.modalShareItemHover } }} styles={styles.modalShareItemWrapper}>
                                    <TwitterShareButton url={val}>
                                        <Div styles={styles.modalShareItem}>
                                            <Div styles={styles.modalShareItemIcon}>
                                                <TwitterIcon
                                                    size={40}
                                                    round={true}
                                                    borderRadius={40}
                                                    iconFillColor={'rgba(29, 161, 242, 1)'}
                                                    bgStyle={{ 'fill': 'transparent' }}
                                                />
                                            </Div>
                                            <Div>Twitter</Div>
                                        </Div>
                                    </TwitterShareButton>
                                </Div>
                                <Div effects={{ hover: { ...styles.modalShareItemHover } }} styles={styles.modalShareItemWrapper}>
                                    <WhatsappShareButton url={val}>
                                        <Div styles={styles.modalShareItem}>
                                            <Div styles={styles.modalShareItemIcon}>
                                                <WhatsappIcon
                                                    size={30}
                                                    iconFillColor={'rgba(39, 210, 70, 1)'}
                                                    bgStyle={{ 'fill': 'transparent' }}
                                                />
                                            </Div>
                                            <Div>WhatsApp</Div>
                                        </Div>
                                    </WhatsappShareButton>
                                </Div>
                                <Div effects={{ hover: { ...styles.modalShareItemHover } }} styles={styles.modalShareItemWrapper}>
                                    <TelegramShareButton url={val}>
                                        <Div styles={styles.modalShareItem}>
                                            <Div styles={styles.modalShareItemIcon}>
                                                <TelegramIcon
                                                    size={30}
                                                    iconFillColor={'rgba(37, 163, 225, 1)'}
                                                    bgStyle={{ 'fill': 'transparent' }}
                                                />
                                            </Div>
                                            <Div>Telegram</Div>
                                        </Div>
                                    </TelegramShareButton>
                                </Div>
                                <Div effects={{ hover: { ...styles.modalShareItemHover } }} styles={{ ...styles.modalShareItemWrapper, ...styles.modalShareItemWrapperLast }}>
                                    <ViberShareButton url={val}>
                                        <Div styles={styles.modalShareItem}>
                                            <Div styles={styles.modalShareItemIcon}>
                                                <ViberIcon
                                                    size={30}
                                                    iconFillColor={'rgba(102, 92, 172, 1)'}
                                                    bgStyle={{ 'fill': 'transparent' }}
                                                />
                                            </Div>
                                            <Div>Viber</Div>
                                        </Div>
                                    </ViberShareButton>
                                </Div>
                            </Div>
                        </Div>

                }
            </Div>

        </>
    )
};

SocialShare.propTypes = {
    btnStyles: PropTypes.object,
    styles: PropTypes.object,
    val: PropTypes.string,
    btnTitle: PropTypes.node,
    positionDropdown: PropTypes.oneOf(['left'])
};
export default Styles(SocialShare, getStyles);