// @generated: @expo/next-adapter@2.1.5
import React, {
    useEffect, useState, useCallback
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import {
    Div, FieldError, svgSprite, Tooltip
} from '../../../../hybrid/wrappers';
import EditTitlteOfCheckbox from './components/EditTitlteOfCheckbox';
import uikit from '../../../../project/uikit/styles';

const Wrapper = (prop) => {
    if (prop.pending){
        return <Tooltip text={prop.pending}>{prop.children}</Tooltip>
    }
    return <>{prop.children}</>
}
const FormCheckboxSwitcher = ({
    pending,
    attribute,
    hint,
    description,
    disabled,
    title,
    handlers = {},
    compStyle,
    value,
    setFormData,
    styles,
    errors,
    visible = true,
    edit = false,
}) => {
    const [val, setVal] = useState(value || false);
    const [showInput, setShowInput] = useState(false);
    const [newTitle, setNewTitle] = useState('');
    const [loading, setLoading] = useState(false);

    const editTitle = () => {
        setShowInput((pre) => !pre);
    }

    const changeHandler = useCallback(() => {
        if (!disabled && !pending) {
            (async () => {
                setFormData(attribute, !val);
                setVal(!val);

                if (handlers?.onChange) {
                    setLoading(true);
                    await handlers.onChange(!val);
                    setLoading(false);
                }
            })();
        }
    }, [disabled, pending, setFormData, attribute, val, handlers]);

    useEffect(() => {
        setVal(value);
    }, [value]);

    if (!visible) return null;
    return (
        <Div styles={{ ...compStyle?.wrapper || {} }}>
            {title?.text &&
                <Div styles={{ ...uikit.flexRow, ...uikit.alignItemsCenter }}>
                    <Div styles={{ ...styles.formCheckboxTitleText, ...compStyle?.text }}>{title.text}</Div>

                    {hint && <Tooltip
                        text={hint}
                        styles={styles.formInfoTooltip}
                        className={title.hintClassName || ''}
                    >
                        {svgSprite('info-circle', {
                            style: {
                                ...styles.formInfoIcon,
                                ...compStyle?.formInfoIcon
                            }
                        })}
                    </Tooltip>}
                </Div>
            }
            <Wrapper pending={pending}>
                <Div
                    styles={{ ...styles.formCheckboxBlock, ...compStyle?.label }}
                >
                    <Div
                        styles={disabled ? styles.formCheckboxBlockDisabled : {}}
                        data-uitest='585_uitest' onClick={!loading ? changeHandler : () => {}}>
                        <Div
                            styles={
                                val && !pending && !loading
                                    ? { ...styles.formCheckboxSwitcher, ...styles.formCheckboxSwitcherActive }
                                    : styles.formCheckboxSwitcher
                            }
                        >
                            <Div
                                styles={
                                    {
                                        ...val
                                            ? { ...styles.formCheckboxSwitcherCircle, ...styles.formCheckboxSwitcherCircleActive }
                                            : styles.formCheckboxSwitcherCircle,
                                        ...pending || loading ? styles.formCheckboxSwitcherPending : {}
                                    }
                                }>

                            </Div>
                        </Div>
                    </Div>

                    {title?.body && !edit &&
                        <Div styles={compStyle?.title || {}}>{title?.body}</Div>
                    }
                    {hint && !title?.text && <Tooltip
                        text={hint}
                        styles={styles.formInfoTooltip}
                        className={title.hintClassName || ''}
                    >
                        {svgSprite('info-circle', {
                            style: {
                                ...styles.formInfoIcon,
                                ...compStyle?.formInfoIcon
                            }
                        })}
                    </Tooltip>}

                    {edit &&
                        <Div>
                            {!showInput
                                ?
                                <Div styles={uikit.flexRow}>
                                    <Div styles={{ 'pointer-events': 'none', ...compStyle?.title } || {}}>{title?.body}</Div>
                                    <Div
                                        styles={styles.formCheckboxSwitcherEditWrapper}
                                        data-uitest='586_uitest' onClick={editTitle}
                                    >
                                        {svgSprite('pen-field', { style: styles.formCheckboxSwitcherEditIcon })}
                                    </Div>
                                </Div>


                                :
                                <Div>
                                    <EditTitlteOfCheckbox
                                        setShowInput={setShowInput}
                                        title={title.body}
                                        styles={styles}
                                        setNewTitle={setNewTitle}
                                        saveEditTitle={edit?.saveEditTitle}
                                        attribute={attribute}
                                        newTitle={newTitle}
                                        number={edit?.number}
                                    />
                                </Div>

                            }
                        </Div>
                    }
                </Div>

            </Wrapper>
            {
                description ? <Div styles={{ ...compStyle?.text || {} }}>{description}</Div> : null
            }
            {
                errors && errors.length ? errors.map((err, idx) => <FieldError styles={styles} key={`${attribute}${idx}`}>{err}</FieldError>) : null
            }
        </Div>
    );
};

FormCheckboxSwitcher.propTypes = {
    pending: PropTypes.node,
    attribute: PropTypes.string,
    hint: PropTypes.node,
    disabled: PropTypes.bool,
    title: PropTypes.object,
    handlers: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.bool,
    setFormData: PropTypes.func,
    styles: PropTypes.object,
    errors: PropTypes.array,
    visible: PropTypes.bool,
    edit: PropTypes.bool
};

export default Styles(FormCheckboxSwitcher, getStyles);