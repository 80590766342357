import React, { useContext } from 'react';
import {
    Div,
    Portal
} from '../../hybrid/wrappers';
import T from './T';
import { navigate } from '../utils/router';
import UserContext from '../utils/getContext';


const BlockedPageModal = ({ blockPageData }) => {
    const { lang } = useContext(UserContext);
    return (
        <Portal customClass='modal modal_blur'>
            <Div styles={{
                'background': '#fff', width: '500px', 'max-width': '100%', 'border-radius': '20px', 'padding': '20px'
            }}>
                <Div styles={{
                    'font-weight': 'bold', 'font-size': '20px',
                    'text-align': 'center', 'margin-bottom': '20px'
                }}>
                    <T textName='block-page-title' defaultStr='Доступ ограничен' page='all'/>
                </Div>
                <Div styles={{ 'margin-bottom': '16px' }}>
                    <T textName='block-page-text-1' vars={{ titlePage: <Div tagName='span' styles={{ 'font-weight': 700 }}>{blockPageData?.page}</Div> }} defaultStr='Для получения доступа к разделу ${titlePage} необходимо успешно завершить соответствующий обучающий урок.' page='all'/>
                </Div>
                <Div styles={{ 'margin-bottom': '16px' }}>
                    <T textName='block-page-text-2' vars={{
                        educationPage: <Div tagName='span'
                            styles={{
                                color: '#BCA57F',
                                cursor: 'pointer',
                                'text-transform': 'uppercase'
                            }}
                            effects={{ hover: { opacity: '0.65' } }}
                            onClick={() => navigate('/[lang]/education', '/education', lang)}>"<T textName='educationPageLink' defaultStr='Обучение' page='all'/>"</Div>,
                        // titlePage: <Div tagName='span' styles={{ 'font-weight': 700 }}>{blockPageData?.page}</Div>,
                        courseName: <Div tagName='span' styles={{
                            color: '#BCA57F',
                            cursor: 'pointer',
                            'text-transform': 'uppercase'
                        }} effects={{ hover: { opacity: '0.65' } }} onClick={() => navigate('/[lang]/education/course/[...operation]', `/education/course/${blockPageData?.lesson?.course_id}/module/${blockPageData?.lesson?.module_id}/lesson/${blockPageData?.lesson?.id}`, lang)}>"{blockPageData?.lesson?.course_name }"</Div>
                    }} defaultStr='Пожалуйста, перейдите на страницу ${educationPage}, в раздел ${courseName}, чтобы приступить к обучающему уроку.' page='all'/>
                </Div>
                <Div styles={{ 'margin-bottom': '16px' }}>
                    <T textName='block-page-text-11' defaultStr='Обучение предоставит Вам ключевые знания и инструменты для оптимального взаимодействия с функционалом этого раздела.' page='all'/>
                </Div>
                <Div styles={{ 'text-align': 'center' }}
                >
                    <Div styles={{
                        // display: 'flex',
                        cursor: 'pointer',
                        'border-radius': '0.875rem',
                        'font-size': '1.125rem',
                        'line-height': '1.3334',
                        'font-weight': 500,
                        padding: '1.25rem',
                        color: 'rgba(255,255,255,1)',
                        'background-color': 'rgba(189,165,127,1)'
                    }}
                    effects={{ hover: { opacity: '0.8' } }}
                    onClick={() => navigate('/[lang]/education/course/[...operation]', `/education/course/${blockPageData?.lesson?.course_id}/module/${blockPageData?.lesson?.module_id}/lesson/${blockPageData?.lesson?.id}`, lang)}
                    >
                        <Div><T textName='block-page-text-btn-link1' defaultStr='Перейти к уроку' page='all'/></Div>
                        <Div styles={{ overflow: 'hidden' }}>"{blockPageData?.lesson?.name}"</Div>
                    </Div>
                </Div>

            </Div>
        </Portal>
    );
};

export default BlockedPageModal;