import React, {
    useState, useCallback, useEffect, useContext, useMemo
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import {
    Div, Img, P, svgSprite, FieldError, FieldInfo, BlankLink
} from '../../../../hybrid/wrappers';
import { useDropzone } from 'react-dropzone';
import UserContext from '../../../../common/utils/getContext';
import T, { t } from '../../../../common/components/T'
import { checkIsfile } from '../../../../common/utils/api';
import FromInputTitle from '../FormFieldTitle';
import { getWidgetTranslates } from '../../../../common/utils/utils';
import { LangContext } from '../../../../common/decorators/Language';
import { useWindowDimensions } from '../../../../common/utils/useWidthDimensions';
import ImageViewer from 'react-simple-image-viewer';


const FormInputDropzone = ({
    attribute,
    max = 999,
    maxScale,
    title,
    button,
    hint,
    compStyle,
    setFormData,
    value,
    multiple = true,
    styles,
    noDrag,
    previewTitle,
    deleteBtn = true,
    infoFile,
    accept = 'image/jpeg, image/jpg, image/png',
    downloading = false,
    exportData = {},
    deletefile= false,
    required,
    info,
    errors,
    disabled = false,
    step = 0,
    openImg = false,
    pdfText = false,
    openModal
}) => {
    const {
        setAlertData, lang, userToken
    } = useContext(UserContext);
    const [transl, setTranslates] = useState(null);
    let [countRequest, setCountRequest] = useState(0);
    const [download, setDownload] = useState(null);
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};
    const [showLoadingFile, setShowLoadingFile] = useState(true);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [imgUrlModal, setImgUrlModal] = useState(false);
    const [isFilePdf, setIsFilePdf] = useState(false)


    const widthDimensions = useWindowDimensions()

    const isDesctop = useMemo(() => widthDimensions > 767, [widthDimensions]);


    const onGetImage = async (link) => {
        try {
            let headers = {
                ...userToken || exportData?.token
                    ? { Authorization: `Bearer ${userToken}` }
                    : {},
                ...lang ? { 'Accept-Language': lang } : {}
            };

            const response = await fetch(`${link}`,
                {
                    method: 'GET',
                    headers
                });

            if (!response.ok) {
                throw new Error(`HTTP Error: ${response.status}`);
            }

            const blob = await response.blob();
            const imageUrl = URL.createObjectURL(blob);


            setImgUrlModal(imageUrl)
        } catch (error) {

        }
    }

    useEffect(() => {
        getWidgetTranslates(lang, 'form', translation, setTranslates);
    }, [lang, translation]);

    useEffect(() => {
        if (disabled) return
        if (download && !countRequest){
            const attribute = multiple ? download?.url : download
            let link = document.createElement('a')
            link.setAttribute('href', attribute)

            link.setAttribute('download', multiple ? download?.fileName || 'file' : `${exportData?.name}.${exportData?.type}`)
            link.click();
        }
    }, [download, countRequest, exportData?.name, exportData?.type, multiple, disabled]);

    const nameForm = !infoFile
        ? t(
            'FormInputDropzone-InfoFile1', 'Перетащите изображение в это поле или воспользуйтесь кнопкой «Загрузить»', 'widget', transl, 'form'
        )
        : t(
            'FormInputDropzone-InfoFile2', 'Перетащите файл в это поле или воспользуйтесь кнопкой «Загрузить»', 'widget', transl, 'form'
        )

    const [val, setVal] = useState(value || multiple ? [] : null);

    async function fileListToBase64(fileList) {
        function getBase64(file) {
            const reader = new FileReader()

            return new Promise(resolve => {
                if (file.preview || file?.links?.preview || file?.url || file?.links?.original){
                    resolve(file.preview || file?.links?.preview || file?.url || file?.links?.original);
                }
                reader.onload = ev => {
                    if (!maxScale){
                        resolve(ev.target.result)
                    } else {
                        let img = new Image();
                        img.onload = function() {
                            if (this.width > maxScale || this.height > maxScale){
                                resolve(false)
                            } else {
                                resolve(ev.target.result)
                            }
                        }
                        img.src = ev.target.result;
                    }
                }
                reader.readAsDataURL(file)
            })
        }
        const promises = []
        for (let i = 0;i < fileList.length;i++) {
            promises.push(getBase64(fileList[i]))
        }
        return await Promise.all(promises)
    }
    const {
        getRootProps, getInputProps, fileRejections
    } = useDropzone({
        noDrag: !!noDrag || disabled,
        accept: accept,
        onDrop: async acceptedFiles => {
            if (disabled) return
            const allFiles = [...acceptedFiles, ...multiple ? val : val ? [val] : []];
            const arrayOfBase64 = await fileListToBase64(allFiles);
            if (acceptedFiles.length > max) {
                setAlertData({
                    type: 'error', cont: `${t(
                        'FormInputDropzone-MaxFileCount', 'Максимальное количество файлов', 'widget', transl, 'form'
                    )} - ${max}`
                });
                return
            }
            if (arrayOfBase64){
                const filtered = allFiles.filter((im, ind) => arrayOfBase64[ind]);
                const arrayOfBase64Filtered = arrayOfBase64.filter((im) => im);
                let newVal = filtered.map((file, index) => {
                    return Object.assign(file, { preview: arrayOfBase64Filtered[index] })
                });
                const slices = multiple || max ? newVal.slice(0, max || 1) : newVal;

                if (filtered.length > slices.length && max !== 1){
                    setAlertData({
                        type: 'error', cont: `${t(
                            'FormInputDropzone-MaxFileCount', 'Максимальное количество файлов', 'widget', transl, 'form'
                        )} - ${max}`
                    });
                    return
                }
                if (multiple){
                    setVal(slices);
                    setFormData(attribute, slices);
                } else {
                    setVal(slices?.length ? slices[0] : null);
                    setFormData(attribute, slices?.length ? slices[0] : null);
                }

                if (allFiles.length > filtered.length){
                    setAlertData({
                        type: 'error', cont: `${t(
                            'FormInputDropzone-MaxFileSize', 'Максимальный размер файла', 'widget', transl, 'form'
                        )} - ${maxScale} x ${maxScale}px`
                    });
                }
            }
        },
        disabled
    });

    useEffect(() => {
        if (fileRejections?.length && fileRejections[0].errors?.length){
            if (fileRejections[0].errors[0].code === 'file-invalid-type'){
                setAlertData({
                    type: 'error', cont: `${t(
                        'FormInputDropzone-PermissibleFileExtension', 'Допустимое расширение файлов', 'widget', transl, 'form'
                    )} - ${accept}`
                });
            } else {
                setAlertData({
                    type: 'error', cont: t(
                        'FormInputDropzone-LoadingFileError', 'Ошибка загрузки файлов', 'widget', transl, 'form'
                    )
                });
            }
        }
    }, [accept, fileRejections, setAlertData, transl])

    const [deletedImages, setDeletedImages] = useState([]);

    const deleteImg = useCallback((file, e) => {
        e.preventDefault();
        e.stopPropagation();
        let newValue = multiple ? val.filter((item) => item !== file) : null;

        setVal(newValue);
        setFormData(attribute, newValue)

        const newDeletedImages = [...deletedImages, file];
        setDeletedImages(newDeletedImages);
        setFormData('deletedImages', newDeletedImages);
    }, [attribute, deletedImages, multiple, setFormData, val]);

    function getInfoAboutFile(param, mime) {
        const name = param?.split('.').splice(0, param?.split('.')?.length - 1);
        const type = mime ? mime?.split('/')?.reverse()[0] : param?.split('.')?.reverse()[0];

        return [name, type];
    }

    const openImageInNewTab = (imageUrl) => {
        window.open(imageUrl, '_blank');
    };

    const openImageViewerModal = useCallback(() => {
        setCurrentImage(0); // Установіть індекс зображення на 0
        onGetImage(openImg)
        setIsViewerOpen(true);
    }, []);
    const openImageViewer = useCallback(() => {
        setCurrentImage(0); // Установіть індекс зображення на 0
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    // const downloadFile = (link, file) => {
    //     (async () => {
    //         let headers = {
    //             'Authorization': `Bearer ${userToken}`,
    //             'Content-Type': 'text/plain',
    //             'Accept': 'application/json',
    //             ...lang ? { 'Accept-Language': lang } : {}
    //         };

    //         const response = await fetch(link, {
    //             method: 'GET',
    //             headers
    //         })

    //         let chunks = [];

    //         if (response.status === 200){
    //             if (response.headers.has('Content-Length')) {
    //                 const reader = response.body.getReader();

    //                 while (true) {
    //                     const { done, value } = await reader.read();

    //                     if (done) {
    //                         break;
    //                     }

    //                     chunks.push(value);
    //                 }
    //                 let blob = new Blob(chunks);

    //                 var myFileRider = new FileReader();
    //                 myFileRider.onloadend = function() {
    //                     setDownload(myFileRider.result);
    //                     setCountRequest(0);
    //                 }
    //                 myFileRider.readAsDataURL(blob)
    //             } else {
    //                 if (multiple) {
    //                     setDownload(file)
    //                     return
    //                 }
    //                 const data = await response.json();
    //                 if (!!data?.data?.finished){
    //                     if (data?.data?.download_link){
    //                         setDownload(data?.data?.download_link);
    //                     }
    //                     setCountRequest(0);
    //                 } else {
    //                     if (data?.data?.download_link){
    //                         setDownload(data?.data?.download_link);
    //                     }
    //                     if (data?.data?.queue_link){
    //                         // setUrl(data?.data?.queue_link);
    //                     }
    //                     setTimeout(() => {
    //                         setCountRequest((pre) => pre+1);
    //                     }, 1500)
    //                 }
    //             }
    //         } else {
    //             setAlertData({
    //                 type: 'error', cont: true, default: 'error_get_data'
    //             });
    //             setCountRequest(-1);
    //         }
    //     })();
    // };

    const onDownloadIpDocument = (link) => {
        let headers = {
            ...userToken || exportData?.token
                ? { Authorization: `Bearer ${userToken}` }
                : {},
            ...lang ? { 'Accept-Language': lang } : {}

        };

        fetch(link
            , {
                method: 'GET',
                headers
            }).then(res => res.blob())
            .then(data => {
                let url = URL.createObjectURL(data)

                let anchor = document.createElement('a')
                anchor.href = url
                anchor.download = 'document-image'
                document.body.appendChild(anchor)
                anchor.click()
                anchor.remove()
            })
    }


    const thumbs = infoFile ? (multiple ? val : val ? [val] : []).map((file, idx) => {
        const [name, type] = getInfoAboutFile(file?.name || file?.original_name || file?.path || file?.exportData?.type, file?.mime_type)
        const size = isNaN(file?.size) ? file?.size : `${Math.round(file?.size / 1024)} ${t(
            'FormInputDropzone-Kbait', 'КБ', 'widget', transl, 'form'
        )}`;

        return (

            <Div
                key={`${file.name}${idx}`}
                styles={compStyle.fileWrapper}
            >
                <Div
                    styles={compStyle?.fileContainer}
                >
                    <Div styles={compStyle.filePosition}>
                        <Div>
                            <P styles={compStyle.subTypePosition}>.{type?.toUpperCase()}</P>
                            {svgSprite('plug-file-image', { width: 56, height: 56 })}
                        </Div>
                        <Div>
                            <P styles={compStyle.subNamePosition}>{name?.length ? name : hint || exportData?.name}</P>
                            {size && <P styles={compStyle.subSizePosition}>{t(
                                'FormInputDropzone-Size', 'Размер', 'widget', transl, 'form'
                            )}{' : '}{size}</P>}
                            <P styles={compStyle.subType2Position}>{t(
                                'FormInputDropzone-Format', 'Формат', 'widget', transl, 'form'
                            )}{': .'}{type}</P>
                        </Div>
                    </Div>
                    <Div styles={compStyle.buttonFile}>
                        {deletefile && !disabled &&
                            <Div data-uitest='592_uitest' onClick={(e) => { deleteImg(file, e) }} styles={styles.formDropzoneDeleteButton}>
                                {t(
                                    'Delete', 'Удалить', 'widget', transl, 'form'
                                )}
                            </Div>
                        }
                        <Div data-uitest='593_uitest' /*onClick={ () => !multiple ? downloadFile(downloading) : downloadFile(file.url, file)}*/>
                            <BlankLink
                                styles={styles.formDeopzoneLink}
                                onClick={e => { e.stopPropagation() }}
                                href={file.url}
                                download={file.fileName}
                                title={t(
                                    'Download', 'Скачать', 'widget', transl, 'form'
                                )}
                            />
                        </Div>
                    </Div>

                </Div>
            </Div>
        )
    })
        : (multiple ? val : val ? [val] : []).map((file, idx) => {
            const isPdf = checkIsfile(file?.preview, ['pdf'])
            || checkIsfile(file?.url, ['pdf'])
            || checkIsfile(file?.links?.preview, ['pdf'])
            || file?.preview?.includes('data:application/pdf')
            || file?.url?.includes('data:application/pdf')
            || file?.links?.preview?.includes('data:application/pdf');


            return (
                <Div key={`${file.url || file?.links?.preview}${file.name}${idx}`} styles={noDrag ? compStyle?.previewImageWrap || {} : { ...styles.formDropzoneImageWrapper, ...compStyle?.previewImageWrap || {} }}>

                    {deleteBtn && !disabled &&
                        <Div data-uitest='594_uitest' onClick={(e) => { deleteImg(file, e) }} styles={styles.formDropzoneLoadButtons}>
                            {svgSprite('trash', {
                                width: '16px',
                                height: '18px',
                                fill: styles.variable.redDarkColor
                            })}
                        </Div>
                    }
                    {isViewerOpen &&
                    <Div styles={{ 'z-index': '15' }} onClick={e => e.stopPropagation()}>
                        <ImageViewer
                            src={[openModal ? imgUrlModal : openImg]} // Зберігайте масив з однією картинкою
                            currentIndex={currentImage}
                            disableScroll={false}
                            closeOnClickOutside={true}

                            onClose={closeImageViewer}
                            backgroundStyle={{ backgroundColor: 'rgb(44 37 37 / 70%)' }}
                        />
                    </Div>
                    }
                    {downloading && openImg ?
                        <Div styles={styles.formDropzoneButtonsWrapper}>
                            <Div data-uitest='595_uitest' onClick={(e) => {
                                e.stopPropagation()
                                openModal ? !disabled && openImageViewerModal() : !disabled && openImageViewer()
                            }} styles={styles.formDropzoneOpenButtons}>
                                {svgSprite('eye', {
                                    width: '19px',
                                    height: '17px',
                                    fill: styles.variable.greyExtraDarkColor
                                })}
                            </Div>
                            <Div styles={styles.formDropzoneButtonsLine}>

                            </Div>
                            <Div data-uitest='595_uitest' onClick={(e) => {
                                e.stopPropagation()
                                !disabled && onDownloadIpDocument(downloading)
                            }} styles={styles.formDropzoneOpenLoadButtons}>
                                {svgSprite('download', {
                                    width: '19px',
                                    height: '17px',
                                    fill: styles.variable.greyExtraDarkColor
                                })}
                            </Div>
                        </Div> : downloading && <Div data-uitest='595_uitest' onClick={(e) => {
                            e.stopPropagation()
                            !disabled && onDownloadIpDocument(downloading)
                        }} styles={styles.formDropzoneLoadButtons}>
                            {svgSprite('download', {
                                width: '19px',
                                height: '17px',
                                fill: styles.variable.greyExtraDarkColor
                            })}
                        </Div>
                    }
                    <Div styles={{ ...styles.formDropzoneImageContent, ...compStyle?.previewImageContent }}>
                        {isPdf
                            ? <iframe src={file?.url || file?.preview || file?.links?.preview} height='233px' width='233px'></iframe>
                            : <Img
                                styles={noDrag ? compStyle?.previewImage || {} : { ...styles.formDropzonePreviewImage, ...compStyle?.previewImage || { ...compStyle.styleOfImg } }}
                                src={file.url || file.preview || file?.links?.preview || file?.links?.original}
                            />
                        }
                    </Div>
                    {noDrag && <P styles={compStyle?.previewName}>{file.name || file?.original_name || 'flag.png'}</P>}
                </Div>
            )
        });

    useEffect(() => {
        setVal(multiple ? value || [] : value || null);
    }, [multiple, value]);

    useEffect(() => {
        setShowLoadingFile(val !== null && multiple || val === null && !multiple ? true : false);
    }, [showLoadingFile, val, multiple]);


    return (
        <Div styles={{ ...styles.formGroup, ...compStyle?.wrapper || {} }}>
            {
                !!title && <FromInputTitle
                    id={`input_${attribute}`}
                    styles={styles}
                    errors={errors}
                    title={title}
                    compStyle={compStyle}
                    hint={title?.hint}
                    required={required}
                    variationStyle={'separated_title'}
                />
            }
            <Div styles={{ ...styles.formDropzoneInner, ...compStyle?.inner || {} }} {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {!noDrag && showLoadingFile &&
                  pdfText && val?.length ?
                    <Div styles={{
                        ...styles.formDropzoneLoadDrop, ...compStyle?.loadDrop, 'background': '#e6ece0'
                    }}>
                        <Div>
                            {
                                svgSprite(infoFile ? 'plug-load-file-image' : 'plug-load-image', { width: '72px', height: '72px' })
                            }
                        </Div>
                        <Div styles={{}}>
                            <Div styles={{ ...compStyle?.loadDropName }}>
                             Файл успешно загружен
                            </Div>
                            <Div styles={{ ...compStyle?.loadDropName, 'font-size': '11px' }}>
                            (Предварительный просмотр файла недоступен из-за большо веса файла)
                            </Div>
                        </Div>
                    </Div>
                    :
                    <Div styles={{ ...styles.formDropzoneLoadDrop, ...compStyle?.loadDrop }}>
                        <Div>
                            {
                                svgSprite(infoFile ? 'plug-load-file-image' : 'plug-load-image', { width: '72px', height: '72px' })
                            }
                        </Div>
                        <Div styles={{ ...compStyle?.loadDropName }}>
                            {nameForm}
                        </Div>
                    </Div>
                }
                {
                    val?.length || !multiple && val ?
                        <Div styles={noDrag ? compStyle?.previewInner || {} : { ...styles.formDropzoneLoadImageContainerPreview, ...compStyle?.previewInner || {} }}>
                            {
                                previewTitle && <P styles={compStyle?.previewTitle || {}}>{previewTitle}</P>
                            }
                            {thumbs}
                        </Div> : null
                }
                {showLoadingFile ?
                    <Div styles={{
                        ...styles.formDropzoneLoadLabelWrap, ...compStyle?.formLoadLabelWrap, 'margin-top': step === 1 ? isDesctop ? '-6px' : '0' : '16px'
                    }}>
                        <P
                            effects={{ hover: { 'opacity': '.8' } }}
                            styles={noDrag ? compStyle?.button || {} : { ...styles.formDropzoneLoadLabel, ...compStyle?.button || {} }}
                        >
                            {
                                button?.icon !== false ? svgSprite(button?.icon || 'painting', {
                                    width: '18px',
                                    height: '18px',
                                    style: { ...styles.formDropzoneLoadLabelIcon, ...compStyle?.buttonIcon }
                                }) : null
                            }

                            {button?.body || <T textName='upload' defaultStr='Загрузить' page='all'/>}
                        </P>
                        {
                            hint &&
                            <Div styles={{ ...styles.formDropzoneText, ...compStyle?.formImageText }}>{hint}</Div>

                        }
                    </Div>
                    : null
                }
            </Div>
            {
                !!info
                && <FieldInfo styles={{ ...styles, ...compStyle?.info ? compStyle?.info : {} }}>{info}</FieldInfo>
            }
            {
                errors && errors.length ? errors.map((err, idx) => <FieldError styles={{ ...styles, ...compStyle?.error || {} }} key={`${attribute}${idx}`}>{err}</FieldError>) : null
            }
            {/* {
                (val?.length || !multiple && val) && !simpleDiv ?
                    <Div styles={noDrag ? compStyle?.previewInner || {} : { ...styles.formDropzoneLoadImageContainerPreview, ...compStyle?.previewInner || {} }}>
                        {
                            previewTitle && <P styles={compStyle?.previewTitle || {}}>{previewTitle}</P>
                        }
                        {thumbs}
                    </Div> : null
            } */}
        </Div>
    );
};

FormInputDropzone.propTypes = {
    attribute: PropTypes.string,
    max: PropTypes.number,
    maxScale: PropTypes.number,
    title: PropTypes.object,
    button: PropTypes.object,
    hint: PropTypes.node,
    compStyle: PropTypes.object,
    setFormData: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    multiple: PropTypes.bool,
    styles: PropTypes.object,
    noDrag: PropTypes.bool,
    previewTitle: PropTypes.node,
    deleteBtn: PropTypes.bool,
    infoFile: PropTypes.bool,
    accept: PropTypes.string,
    downloading: PropTypes.bool,
    exportData: PropTypes.object,
    deletefile: PropTypes.bool,
    required: PropTypes.bool,
    errors: PropTypes.array,
    openImg: PropTypes.bool
};

export default Styles(FormInputDropzone, getStyles);