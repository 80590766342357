import React, {
    useState, useEffect, useRef, useContext
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import { getGeoData, getGeoName } from '../../../../common/utils/memo';
import { API_SERVER_URL } from '../../../../common/utils/utils';
import FormSelect from './FormSelect'
import UserContext from '../../../../common/utils/getContext';

const countries = [0, 'country_id', 'delivery_country_id', 'cdek_country', 'country']
const states = [0, 'region_id', 'delivery_state_id', 'cdek_region']
const cities = [0, 'city_id', 'delivery_city_id', 'cdek_city']

const getIsCountry = (a) => {
    const matchResult = a.match(/^(.*?)-(\d+)(-door)$/) || a.match(/^(.*?)-(\d+)$/);
    let attributeName
    if (matchResult){
        attributeName = matchResult[1];
    }
    if (countries.indexOf(a) !== -1){
        return countries.indexOf(a)
    } else if (countries.indexOf(attributeName) !== -1){
        return countries.indexOf(attributeName)
    } else {
        return false
    }
}
const getIsState = (a) => {
    const matchResult = a.match(/^(.*?)-(\d+)(-door)$/) || a.match(/^(.*?)-(\d+)$/);
    let attributeName
    if (matchResult){
        attributeName = matchResult[1];
    }

    if (states.indexOf(a) !== -1){
        return states.indexOf(a)
    } else if (states.indexOf(attributeName) !== -1){
        return states.indexOf(attributeName)
    } else {
        return false
    }
}
const getIsCity = (a) => {
    const matchResult = a.match(/^(.*?)-(\d+)(-door)$/) || a.match(/^(.*?)-(\d+)$/);
    let attributeName
    if (matchResult){
        attributeName = matchResult[1];
    }

    if (cities.indexOf(a) !== -1){
        return cities.indexOf(a)
    } else if (cities.indexOf(attributeName) !== -1){
        return cities.indexOf(attributeName)
    } else {
        return false
    }
}
const getDisabled = (attribute, formData, regionName) => {
    if (attribute.includes('cdek_region')) return false
    if (attribute.includes('cdek_city') && regionName) return false
    if (attribute.includes('cdek_point') && regionName) return false
    if (attribute.includes('cdek_point')) return true
    if (getIsCountry(attribute)) return false;
    if (getIsState(attribute)){
        return !Boolean(formData[countries[getIsState(attribute)]])
    }
    if (getIsCity(attribute)){
        return !Boolean(formData[states[getIsCity(attribute)]])
    }
}
const FormDropdown = (props) => {
    const [options, setOptions] = useState(false)
    const [value, setValue] = useState(props.value)
    const nextPage = useRef(null)
    const searchQ = useRef(null)

    const [load, setLoad] = useState(false)
    const [prevValue, setPrevValue] = useState(getIsState(props.attribute) ? props.formData.country_id : getIsCity(props.attribute) ? props.formData.region_id : false)


    const { userToken, lang } = useContext(UserContext);

    useEffect(() => {
        if (getIsState(props.attribute) && props.formData[countries[getIsState(props.attribute)]] !== prevValue){
            setOptions(false)
            setPrevValue(props.formData[countries[getIsState(props.attribute)]])
        } else if (getIsCity(props.attribute) && props.formData[states[getIsCity(props.attribute)]] !== prevValue){
            setOptions(false)
            setPrevValue(props.formData[states[getIsCity(props.attribute)]])
            if (nextPage.current) {
                nextPage.current.value = null;
            }
            if (searchQ.current) {
                searchQ.current.value = null;
            }
        }
        if (props?.filtered) {
            setOptions(false)
        }
    }, [prevValue, props.attribute, props.formData, props])
    useEffect(() => {
        setValue(props.value)
    }, [props.value])
    useEffect(() => {
        (async () => {
            if (props?.value?.value){
                getGeoName(props?.value?.value, lang)
                    .then((res) => {
                        setValue({
                            ...props?.value,
                            label: res?.data?.name
                        })
                    })
            }
        })();
    }, [lang, props?.value, userToken])

    const getStates = async (q, page, next, callback) => {
        let url = getIsCountry(props.attribute)
            ? props.url ? `${API_SERVER_URL}${props.url}` : `${API_SERVER_URL}/api/v1/geonames/countries`
            : getIsState(props.attribute)
                ? props.attribute.includes('cdek_region') ? props.cityType === 'cdek_door' ? `${API_SERVER_URL}/api/v1/user/cdek/doors/regions` : props.cityType === 'admin_cdek' ? `${API_SERVER_URL}/api/v1/admin/cdek/regions` : `${API_SERVER_URL}/api/v1/user/cdek/regions` : props.formData[countries[getIsState(props.attribute)]]?.links?.states_of_country || `${API_SERVER_URL}/api/v1/geonames/states`
                : props.attribute.includes('cdek_city') ? props.cityType === 'cdek_door' ? `${API_SERVER_URL}/api/v1/user/cdek/doors/cities` : props.cityType === 'admin_cdek' ? `${API_SERVER_URL}/api/v1/admin/cdek/all/cities` : `${API_SERVER_URL}/api/v1/user/cdek/cities` :
                    props.attribute.includes('cdek_point')? `${API_SERVER_URL}/api/v1/user/cdek/delivery-points/${props.regionName}`
                        : props.formData[states[getIsCity(props.attribute)]]?.links?.cities_of_state || `${API_SERVER_URL}/api/v1/geonames/cities`;


        let data = []

        if (props?.filtered?.length) {
            data = { data: props.filtered }
        } else {
            let pageSize = props.attribute.includes('cdek_region') || props.attribute.includes('cdek_city') || props.attribute.includes('cdek_point') ? 200 : 10
            data = await getGeoData(url + `?page[size]=${pageSize}` + (next ? '&page[number]=' + next : '') + (q ? '&filter[q]=' + q : ''), userToken, lang, props.attribute.includes('cdek_city') && props.regionName)
        }

        setOptions((pre) => {
            if (pre && page && props.attribute.includes('cdek_region')){
                return [...pre, ...data.data.map((item) => ({
                    ...item, value: item.region, id: item.region, label: item.region
                }))]
            } else if (props.attribute.includes('cdek_region')){
                return data.data.map((item) => ({
                    ...item, value: item.region, id: item.region, label: item.region
                }))
            } else if (pre && page && props.attribute.includes('cdek_city')){
                return [...pre, ...data.data.map((item) => ({
                    ...item, value: item.city, id: item.id, label: item.city
                }))]
            } else if (props.attribute.includes('cdek_city')){
                return data.data.map((item) => ({
                    ...item, value: item.city, id: item.id, label: item.city
                }))
            } else if (pre && page && props.attribute.includes('cdek_point')){
                return [...pre, ...data.data.map((item) => ({
                    ...item, value: item.code, label: item.code
                }))]
            } else if (props.attribute.includes('cdek_point')){
                return data.data.map((item) => ({
                    ...item, title: item?.address, value: item?.city, id: item?.id, label: item?.city
                }))
            } else if (pre && page) {
                return [...pre, ...data?.data?.map((item) => ({
                    ...item, title: item?.name, id: item?.geoname_id
                }))]
            } else {
                return data?.data?.map((item) => ({
                    ...item, title: item?.name, id: item?.geoname_id
                }))
            }
        })

        if (data?.meta?.current_page < data?.meta?.last_page) {
            if (nextPage.current) {
                nextPage.current.value = data?.meta?.current_page + 1;
            }
        } else {
            if (nextPage.current) {
                nextPage.current.value = null;
            }
        }
        if (q) {
            if (searchQ.current) {
                searchQ.current.value = q;
            }
        } else {
            if (searchQ.current) {
                searchQ.current.value = null;
            }
        }
        if (callback){
            callback(data?.data?.map((item) => ({
                ...item, label: item.name, value: item?.geoname_id
            })))
        }
    }
    const searchHandler = async (e, callback) => {
        if (getIsCity(props.attribute)){
            await getStates(e, false, false, callback)
        }
    }
    const loadOptions = async () => {
        if ((props?.cityType === 'cdek_door' || props?.cityType === 'cdek') && !load || !options && !load){
            setLoad(true)
            setOptions([])
            await getStates('', false, 1)
            setLoad(false)
        }
    }
    const onMenuScrollToBottom = async (e, q) => {
        if (e.current?.value){
            setLoad(true)
            await getStates(q.current?.value, true, e.current?.value)
            setLoad(false)
        }
    }
    const changeHandler = (e, attribute) => {
        if (props?.deliveryCustom && (attribute == 'country_id' || attribute == 'city_id' || attribute == 'region_id')) {
            props.setFormData('delivery_point_id', null)
        }
        if (getIsCountry(attribute) && states[getIsCountry(attribute)] && cities[getIsCountry(attribute)]) {
            if (e) {
                props.setFormData(states[getIsCountry(attribute)], null)
                props.setFormData(cities[getIsCountry(attribute)], null)
                props.setFormData(attribute, e)
            } else {
                props.setFormData(states[getIsCountry(attribute)], null)
                props.setFormData(cities[getIsCountry(attribute)], null)
                props.setFormData(attribute, null)
            }
        } else if (getIsState(attribute) && cities[getIsState(attribute)] && cities[getIsState(attribute)]) {
            if (e) {
                props.setFormData(cities[getIsState(attribute)], null)
                props.setFormData(attribute, e)
            } else {
                props.setFormData(cities[getIsState(attribute)], null)
                props.setFormData(attribute, null)
            }
        } else {
            if (e) {
                props.setFormData(attribute, e)
            } else {
                props.setFormData(attribute, null)
            }
        }
    }

    useEffect(() => {
        if (props.preparedLoad) {
            loadOptions()
        }
    // eslint-disable-next-line
    }, [props.preparedLoad])

    useEffect(async () => {
        if (props.regionName && !load) {
            setOptions(false)
            setLoad(true)
            await getStates('', false, 1)
            setLoad(false)
        }
    }, [props.regionName])


    const disabled = props?.customDisabled ? props.customDisabled : getDisabled(props.attribute, props.formData, props.regionName)
    return <><input type='hidden' ref={nextPage}/><input type='hidden' ref={searchQ}/><FormSelect
        {...props}
        disabled={disabled}
        options={props.removeKz && Array.isArray(options) ? options.filter(el => el.country !== 'KZ') : options}
        isClear={false}
        value={value}
        isLoading={load}
        loadOptions={getIsCity(props.attribute) && !props.moreOptions ? searchHandler : false}

        handlers={{
            ...props.handlers || {},
            onChange: (e, a) => { changeHandler(e, a) },
            onMenuOpen: async (a) => { await loadOptions(a) },
            onMenuScrollToBottom: async () => { await onMenuScrollToBottom(nextPage, searchQ) }
        }}
    /></>
};

FormDropdown.propTypes = {
    attribute: PropTypes.string,
    setFormData: PropTypes.func,
    handlers: PropTypes.object,
    formData: PropTypes.object
};

export default Styles(FormDropdown, getStyles);