// @generated: @expo/next-adapter@2.1.5
import React, {
    useContext, useState, useEffect
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    svgSprite, Div, Tooltip
} from '../../../../../hybrid/wrappers';
import UserContext from '../../../../../common/utils/getContext';
import QrCode from '../../../../qrCode/qrCode';
import { t } from '../../../../../common/components/T'
import { LangContext } from '../../../../../common/decorators/Language';
import { getWidgetTranslates } from '../../../../../common/utils/utils';
import SocialShare from '../../../../../hybrid/library/SocialShare';

const CopyType = ({
    styles, compStyle, copy, message, val
}) => {
    const { setAlertData, lang } = useContext(UserContext);
    const [qrModal, setQrModal] = useState(false);
    const [transl, setTranslates] = useState(null)

    const {
        qr, positionDropdown, hint, hideShare
    } = copy;
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    useEffect(() => {
        getWidgetTranslates(lang, 'form', translation, setTranslates);
    }, [lang, translation]);

    return (
        <>
            {
                qr ?
                    <>
                        <Div
                            data-uitest='596_uitest' onClick={() => { setQrModal(true) }}
                            styles={{ ...styles.formControlIconWrap, ...compStyle?.copy || {} }}
                            effects={{
                                hover: styles.formControlIconWrapHover
                            }}
                        >
                            {
                                svgSprite('qr', {
                                    width: '19px',
                                    height: '20px',
                                    fill: styles.variable.greyExtraDarkColor
                                })
                            }
                        </Div>
                        {
                            qrModal &&
                          <QrCode title={qr} closeHandler={setQrModal} val={val}/>

                        }
                    </>
                    : null
            }

            <CopyToClipboard
                text={val}
                onCopy={() => setAlertData({
                    type: 'success', cont: message?.copy || message?.success || t(
                        'FormCopy-CopyReferalLink-success1', 'Реферальная ссылка успешно скопирована', 'widget', transl, 'form'
                    )
                })}
                effects={{
                    hover: styles.formControlIconWrapHover
                }}
            >
                <Div>
                    <Tooltip styles={{ ...styles.formControlIconWrap, ...compStyle?.copy || {} }} text={hint || t(
                        'FormCopy-CopyReferalLink', 'Скопируйте реферальную ссылку сайта', 'widget', transl, 'form'
                    )}>
                        {svgSprite('file-copy', {
                            width: '20px',
                            height: '20px',
                            fill: styles.variable.greyExtraDarkColor
                        })}
                    </Tooltip>
                </Div>
            </CopyToClipboard>

            {!hideShare
            &&
               <SocialShare
                   btnStyles={{ ...styles.formControlIconWrap, ...compStyle?.copy || {} }}
                   btnStylesHover={{ ...styles.formControlIconWrapHover, ...compStyle?.copy  || {} }}
                   title={qr} val={val} positionDropdown={positionDropdown}/>
            }

        </>
    );
};

export default CopyType;