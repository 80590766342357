// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import {
    Div,
    A,
    P,
    BlankLink
} from '../../../hybrid/wrappers';

const Links = ({
    val, gridStyles, name
}) => {
    const link = {
        show: val.show || val,
        url: val.url || val
    }

    const innerStyles = { display: 'flex' }

    const title =
        <P styles={innerStyles}>
            <P styles={gridStyles?.[name]?.slash}>/</P>
            <P styles={gridStyles?.[name]?.link}>{link.show}</P>
        </P>;

    return (
        <Div>
            {val?.blankLink? <BlankLink title={title} href={link.url}/> : <A title={title} href={`/${link.url}`}/>}
        </Div>
    )
};

Links.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    styles: PropTypes.object,
    name: PropTypes.string,
    link: PropTypes.string,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ])
};

export default Links;