import React, { useContext } from 'react';
import {
    Div,
    Portal,
    A
} from '../../hybrid/wrappers';
import T from './T';
import { navigate } from '../utils/router';
import UserContext from '../utils/getContext';


const BlockedPageTextModal = ({  }) => {
    const { lang } = useContext(UserContext);
    return (
        <Portal customClass='modal modal_blur'>
            <Div styles={{
                'background': '#fff', width: '500px', 'max-width': '100%', 'border-radius': '20px', 'padding': '20px'
            }}>
                <Div styles={{
                    'font-weight': 'bold', 'font-size': '20px',
                    'text-align': 'center', 'margin-bottom': '20px'
                }}>
                    <T textName='block-page-text-title' defaultStr='Доступ к этой странице ограничен' page='all'/>
                </Div>
                <Div styles={{ 'margin-bottom': '16px' }}>
                    <T textName='block-page-text-text-1' defaultStr='Чтобы получить доступ, вам необходимо подтвердить статус партнера. Перейдите в личный кабинет и пройдите регистрацию партнера' page='all'/>
                </Div>
                <Div styles={{ 'margin-bottom': '16px' }}>

                    <T textName='block-page-text-text-2' defaultStr='Если у вас возникли вопросы, обратитесь в' page='all'/>{' '}
                    <A href={`/${lang}/contact`}
                       styles={{ display: 'inline', color:'rgba(189,165,127,1)' }}
                       effects={{ hover: { opacity: '0.65' } }}
                       title={<T textName='block-page-text-text-3' defaultStr='службу поддержки.' page='all'/>}/>
                </Div>
                <Div styles={{ 'text-align': 'center' }}
                >
                    <Div styles={{
                        // display: 'flex',
                        cursor: 'pointer',
                        'border-radius': '0.875rem',
                        'font-size': '1.125rem',
                        'line-height': '1.3334',
                        'font-weight': 500,
                        padding: '1.25rem',
                        color: 'rgba(255,255,255,1)',
                        'background-color': 'rgba(189,165,127,1)'
                    }}
                         effects={{ hover: { opacity: '0.8' } }}
                         onClick={() => navigate('/[lang]/office', `/office`, lang)}
                    >
                        <Div><T textName='block-page-text-text-btn-link1' defaultStr='В личный кабинет' page='all'/></Div>
                    </Div>
                </Div>

            </Div>
        </Portal>
    );
};

export default BlockedPageTextModal;