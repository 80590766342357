import React, {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
    Div, FieldError, FieldInfo
} from '../../../../hybrid/wrappers';
import FromInputTitle from '../FormFieldTitle';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import { initFormHandlers } from '../../formUtils';
import UserContext, { UserProfileContext } from '../../../../common/utils/getContext'
import AsyncSelect from 'react-select/async';
import { getWidgetTranslates } from '../../../../common/utils/utils';
import { LangContext } from '../../../../common/decorators/Language';
import {
    getConfig, getCustomStyles, getOptions
} from './helpers';
import { getDefaultVariation, getIsCoupleVariation } from '../helpers';

const FormDropdown = ({
    attribute,
    required,
    disabled,
    title,
    hint,
    handlers = {},
    compStyle,
    value,
    default_value,
    type,
    setFormData,
    info,
    styles,
    placeholder,
    errors,
    options,
    portal,
    isClear,
    isLoading,
    className,
    variation,
    multiple,
    selectedLimit = 2,
    limit = 999,
    insertComponent,
    loadOptions,
    customChangeFunc,
    isSearchable,
    noOptionsText,
    description
}) => {
    const profileContext = useContext(UserProfileContext);

    const variationStyle = variation || getDefaultVariation(profileContext, title)

    const [transl, setTranslates] = useState(null)
    const { lang } = useContext(UserContext);
    const { translation } = useContext(LangContext) ? useContext(LangContext) : {};

    useEffect(() => {
        getWidgetTranslates(lang, 'form', translation, setTranslates);
    }, [lang, translation]);


    const optionsConfig = useMemo(() => {
        if (loadOptions){
            return { loadOptions, defaultOptions: getOptions(options) }
        } else {
            return (
                { options: getOptions(options) }
            )
        }
    }, [loadOptions, options])

    const [val, setVal] = useState(value || []);
    const initHandlers = useMemo(() => initFormHandlers(
        handlers, attribute, val, type, errors
    ),
    [handlers, attribute, val, type, errors]);

    const changeHandler = useCallback((item) => {
        if (!disabled) {
            if (item) {
                setFormData(attribute, item);
            } else {
                setFormData(attribute, {
                    value: '',
                    label: ''
                });
            }
            !customChangeFunc && setVal(item);
            if (handlers && handlers.onChange) {
                handlers.onChange(item, attribute);
            }
        }
    }, [disabled, handlers, setFormData, attribute, customChangeFunc]);

    useEffect(() => {
        setVal(pre => {
            if (pre?.value !== value?.value || pre?.label !== value?.label || pre?.length || value?.length) {
                if (value && typeof value === 'string') return pre;
                return value;
            }
            return pre
        })
    }, [value, default_value]);

    const customStyles = getCustomStyles(
        styles, profileContext, multiple, val, isClear, variationStyle, compStyle, errors, disabled
    )

    const noOptionsMessage = () => noOptionsText || 'No option';
    const config = getConfig(
        val, attribute, title, hint, required, disabled, placeholder, optionsConfig, portal, customStyles, multiple, limit, selectedLimit, initHandlers, setVal, setFormData, changeHandler, variationStyle, styles, compStyle, insertComponent, errors, isLoading, transl, isClear, className, isSearchable, noOptionsMessage
    )
    return (
        <>
            <Div styles={{ ...styles.formGroup, ...compStyle?.wrapper || {} }}>
                {
                    !!title && !getIsCoupleVariation(variationStyle) ?
                        <FromInputTitle
                            styles={styles}
                            errors={errors}
                            title={title}
                            compStyle={compStyle}
                            hint={hint}
                            required={required}
                            variationStyle={variationStyle}
                        /> : null

                }
                {loadOptions ? <AsyncSelect cacheOptions={true} {...config}/> : <Select {...config}/>}
                {
                    !!info
                    && <FieldInfo styles={{ ...styles, ...compStyle?.info ? compStyle?.info : {} }}>{info}</FieldInfo>
                }
                    {
                            description ? <Div styles={{ ...compStyle?.text || {} }}>{description}</Div> : null
                    }

                {
                    errors && errors.length ? errors.map((err, idx) => <FieldError
                        styles={{ ...styles, ...compStyle?.error || {} }} key={`${attribute}${idx}`}>{err}</FieldError>) : null
                }
            </Div>


        </>
    );
};

FormDropdown.propTypes = {
    attribute: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.object,
    hint: PropTypes.node,
    handlers: PropTypes.object,
    compStyle: PropTypes.object,
    value: PropTypes.object,
    default_value: PropTypes.object,
    type: PropTypes.string,
    setFormData: PropTypes.func,
    info: PropTypes.node,
    styles: PropTypes.object,
    placeholder: PropTypes.node,
    errors: PropTypes.array,
    options: PropTypes.array,
    portal: PropTypes.bool,
    isClear: PropTypes.bool,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    variation: PropTypes.string,
    multiple: PropTypes.bool,
    selectedLimit: PropTypes.number,
    limit: PropTypes.number,
    insertComponent: PropTypes.node,
    loadOptions: PropTypes.array,
    customChangeFunc: PropTypes.bool
};
export default Styles(FormDropdown, getStyles);