import React, { useState, useContext } from 'react'
import { Div } from '../../hybrid/wrappers'
import FormInput from '../form/components/form-input/FormInput'
import UserContext from './../../common/utils/getContext'
import CartFilterSearchItem from './CartFilterSearchItem';
import { getData } from './../../common/utils/router';
import { t } from './../../common/components/T';

const CartFilterItem = ({
    styles,
    funcs,
    setErrorData,
    translation,
    storeAlias
}) => {
    const [search, setSearch] = useState(false)
    const [q, setQ] = useState('')
    const [searchDB, setSearchDB] = useState([])
    const {
        userToken, setAlertData, lang
    } = useContext(UserContext);
    const searchGlobalFunc = async (text) => {
        const getParams = `?filter[status]=active&filter[q]=${text}`
        const searchData = await getData(`/api/v1/admin/shop/products${getParams}`,
            userToken,
            lang, false, false, false, storeAlias
            );

        const globalErr = searchData.globalErr || searchData.globalErr;
        if (globalErr) { setAlertData({ type: 'error', cont: globalErr }); } else if (searchData) {
            await setSearchDB(searchData.data)
            if (searchData.data.length > 0 && text) {
                setSearch(true)
            }
        }
    };
    const config = {
        attribute: 'product',
        type: 'input',
        search: { type: 'onInput', icon: 'search-thin' },
        placeholder: t('storeOrder-CartFilterItem-placeholder', 'Введите название товара или артикул для поиска', '/admin/store/order', translation),
        handlers: {
            onChange: (v) => {
                searchGlobalFunc(v)
                !v && setSearch(false)
                setQ(v)
            }
        },
        compStyle: { wrapper: { 'margin-bottom': '0' } },
        value: q
    }
    const addProduct = async (id) => {
        const data = await getData(`/api/v1/admin/shop/products/${id}`,
            userToken,
            lang,
            false, false,false, storeAlias
            );

        const globalErr = data.globalErr || data.globalErr;
        if (globalErr) {
            setAlertData({ type: 'error', cont: globalErr });
        } else if (data) {
            const product = data.data
            setErrorData([])
            setSearch(false)
            setQ('')
            funcs.add(product)
        }
    }
    return (
        <Div>
            <Div styles={styles.filterSearchWrapper}>
                <FormInput {...config}/>
                {search && <Div styles={styles.resultSearch}>
                    {
                        searchDB && searchDB.length > 0 ? searchDB?.map((item, idx) =>
                            <CartFilterSearchItem
                                key={`CartFilterSearchItem_${idx}`}
                                styles={styles}
                                item={item}
                                addProduct={addProduct}
                                translation={translation}
                            />):
                            <Div>{t('storeOrder-CartFilterItem-noProductFilter', 'Не найдено ни одного товара. Измените поисковые данные', '/admin/store/order', translation)}</Div>
                    }
                </Div>}
            </Div>

        </Div>
    )
}
export default CartFilterItem