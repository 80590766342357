import React, {
    useState, useCallback, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import getStyles from '../../../../project/styles/widget-styles/form-styles';
import Styles from '../../../../common/decorators/Styles';
import UserContext from '../../../../common/utils/getContext'
import { getData } from '../../../../common/utils/router';
import FormSelect from './FormSelect';

const FormDropdownSearch = (props) => {
    const {
        attribute,
        disabled,
        handlers = {},
        value,
        setFormData,
        routePage,
        searchParams,
        multiple = true,
        storeAlias = false,
    } = props;
    const [dropData, setDropData] = useState([])
    const {
        userToken, setAlertData, lang
    } = useContext(UserContext);
    useEffect(() => {
        (async () => {
            if(routePage) {
                const data = await getData(`/api/v1/${routePage}`, userToken, lang, false, false ,false, storeAlias);
                const globalErr = data.globalErr || data.globalErr;
                if (globalErr) {
                    setAlertData({type: 'error', cont: globalErr});
                } else if (data) {
                    let newData = [];
                    let getData = data?.data || data?.Data;
                    getData?.map((elem) => {
                        const funcChild = (item, idx) => {
                            idx++
                            if (item.children && item.children.length) {
                                let line = ''
                                const oneLine = '- '
                                for (let i = 0; i < idx; i++) {
                                    line = line + oneLine
                                }

                                item.children.map((child) => {
                                    newData.push({
                                        ...child,
                                        name: `${line}${child.name}`
                                    })
                                    funcChild(child, idx)
                                })
                            }
                        }
                        newData.push(elem)
                        funcChild(elem, 0)
                    })
                    newData = newData
                        .filter((el) => el.name || el.code || el.title || el?.TradePointName)
                        .map((el) => ({
                            id: el.id || el?.TradePointId,
                            title: el.name || el.code || el.title || el?.TradePointName || '-'
                        }));
                    setDropData(newData)
                    let newValSingle = {};
                    value && !multiple && Object.keys(value).map((valueElem) => {
                        if (value?.label && value?.label !== null && value?.label !== '') {
                            newValSingle = value
                        } else {
                            let curId = value?.id || value?.value || value?.TradePointId;
                            newValSingle = {
                                ...valueElem,
                                label: newData?.filter((item) => Number(item.id) === Number(curId))?.[0]?.title || ''
                            }
                        }
                    });
                    value && !multiple && setVal(newValSingle);
                }
            }
        })();
    }, [userToken, setAlertData, lang, routePage]);
    const searchGlobalFunc = async (text, callback) => {
        const getParams = `?filter[${searchParams}]=${text}`
        const searchData = await getData(`/api/v1/${routePage}${getParams}`, userToken, lang, false, false ,false, storeAlias);
        const globalErr = searchData.globalErr;
        if (globalErr) {
            setAlertData({ type: 'error', cont: globalErr });
        } else if (searchData && searchData?.data) {
            let newData = []
            searchData?.data?.map((elem) => {
                const funcChild = (item, idx) => {
                    idx++
                    if (item.children && item.children.length) {
                        let line = ''
                        const oneLine = '- '
                        for (let i = 0;i < idx;i++) {
                            line = line + oneLine
                        }

                        item.children.map((child) => {
                            newData.push({
                                ...child,
                                name: `${line}${child.name}`
                            })
                            funcChild(child, idx)
                        })
                    }
                }
                newData.push(elem)
                funcChild(elem, 0)
            })
            newData = newData.map((el) => ({
                value: el.id,
                label: el.name || el.code || el.title || '-'
            }))
            callback(newData)
        }
    };

    const [val, setVal] = useState(value);

    const changeHandler = useCallback((item) => {
        if (!disabled && item.length) {
            setFormData(attribute, item);
            setVal(item);
        } else if (item.length === 0) {
            setFormData(attribute, item)
        }
    }, [disabled, setFormData, attribute]);

    // useEffect(() => {
    //     if (value) {
    //         setFormData(attribute, value);
    //         setVal(value || []);
    //     }
    // }, [attribute, setFormData, value]);

    return (
        <FormSelect
            {...props}
            options={dropData}
            multiple={multiple}
            // isClear={true}
            value={val}
            loadOptions={searchGlobalFunc}
            handlers={{
                ...props.handlers || {},
                onChange: (e) => {
                    changeHandler(e);
                    handlers?.onBlur && handlers?.onBlur(attribute, e);
                }
            }}
        />
    )
};

FormDropdownSearch.propTypes = {
    attribute: PropTypes.string,
    disabled: PropTypes.bool,
    handlers: PropTypes.object,
    value: PropTypes.array,
    setFormData: PropTypes.func,
    searchParams: PropTypes.string,
    routePage: PropTypes.string
};

export default Styles(FormDropdownSearch, getStyles);