// @generated: @expo/next-adapter@2.1.5
import React, {
    useMemo, useState, useRef, useEffect, useCallback
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
    Div, Img, svgSprite
} from '../../../hybrid/wrappers';
import { rankColors } from '../../../project/uikit/color-variables';

import { t } from '../../../common/components/T';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';

import rankIcon1 from '../../../public/src/img/office/rank/rankIcon_1.svg';
import rankIcon0 from '../../../public/src/img/office/rank/rankIcon_0.svg';

const Rang = ({
    getHandlers,
    gridStyles,
    val,
    name,
    styles,
    align,
    gridName,
    refTable,
    refTableChild,
    idx,
    thead,
    translation
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const modal = useRef(false);
    const modalDropdown = useRef(false);
    const modalInner = useRef(false);
    const rankColor = useMemo(() => {
        if (typeof val === 'string') return {}
        if (val?.preview?.url || val?.preview?.links?.preview) {
            return rankColors[val.sort] || rankColors[0]
        }
        return rankColors[val.sort] || rankColors[0]
    }, [val]);

    const widthDimensions = useWindowDimensions();
    const isDesktop = widthDimensions > 1024;
    const leftSide = widthDimensions < 768 && idx === thead.length - 1;

    const func = useCallback(() => {
        if (modal?.current) {
            const width = modalInner?.current?.getBoundingClientRect()?.width;
            const height = modalInner?.current?.getBoundingClientRect()?.height;
            const top = modalDropdown?.current?.getBoundingClientRect()?.top - refTable?.current?.getBoundingClientRect()?.top;
            const left = modalDropdown?.current?.getBoundingClientRect()?.left - refTable?.current?.getBoundingClientRect()?.left;
            modal.current.style.height = `${height}px`;
            modal.current.style.top = `${top}px`;
            modal.current.style.left = `${left - (leftSide ? width - 50 : 0)}px`;
            modal.current.style.width = `${width}px`;
        }
    }, [leftSide, refTable])

    useEffect(() => {
        const refTableChildVar = refTableChild?.current;
        func();
        if (refTableChildVar) {
            refTableChildVar.addEventListener('scroll', func)
        }

        return () => {
            refTableChildVar?.removeEventListener('scroll', func)
        }
    }, [func, refTableChild])

    useEffect(() => {
        func()
    }, [isOpen, setIsOpen, func])

    if (isDesktop) return (
        <Div
            {...getHandlers(false)}
            styles={{ ...styles.rangConteiner, ...align === 'left' ? styles.rangConteinerLeft : align === 'right' ? styles.rangConteinRight : {} }}
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            effects={gridStyles[name]?.wrapper?.effects || {}}
        >
            {
                (
                    <Div styles={{ display: 'flex', position: 'relative' }}>
                        <Div
                            styles={{ ...styles.rangSvgBox }}
                            childrenRef={modalDropdown}
                        >
                            {val?.preview?.url || val?.preview?.links?.preview ?
                                <Img
                                    src={`${val ? val?.preview?.url || val?.preview?.links?.preview : 0}`}
                                    styles={gridStyles[name]?.ico?.styles || {
                                        width: '34px',
                                        height: '34px',
                                        'border-radius': '34px',
                                        overflow: 'hidden'
                                    }}
                                />
                                :
                                <Div styles={{
                                    'border': '2px solid #fff', display: 'inline-flex', 'border-radius': '50%'
                                }}>
                                    <Img src={`/src/img/office/rank/rankIcon_${val.sort || 0}.svg`} source={`rankIcon${val.sort || 0}`}/>
                                </Div>
                            }
                        </Div>
                        {
                            typeof val === 'string' ?
                                <Div styles={{
                                    ...styles.rangName, ...leftSide ? styles.rangNameLeft : {}, background: rankColor.back, 'z-index': 1
                                }}>
                                    {val || t(
                                        'grid-withoutQual', 'Без квалификации', 'widget', translation, 'grid'
                                    )}
                                    <Div styles={styles.borderRangName} />
                                </Div>
                                :
                                <Div styles={{
                                    ...styles.rangName, ...leftSide ? styles.rangNameLeft : {}, background: rankColor.back, 'z-index': 1
                                }}>
                                    {val?.name || t(
                                        'grid-withoutQual', 'Без квалификации', 'widget', translation, 'grid'
                                    )}
                                </Div>

                        }

                    </Div>
                )
            }
        </Div>
    )
    return (
        <Div
            {...getHandlers(false)}
            styles={{ ...styles.rangConteiner, ...align === 'left' ? styles.rangConteinerLeft : align === 'right' ? styles.rangConteinRight : {} }}
            effects={gridStyles[name]?.wrapper?.effects || {}}
            onClick={() => {
                setIsOpen(true)
                setTimeout(() => { setIsOpen(false) }, 2000)
            }}
        >

            <Div styles={{ display: 'flex', position: 'relative' }}>
                <Div
                    styles={styles.rangSvgBox}
                    childrenRef={modalDropdown}
                >
                    {val?.preview?.url || val?.preview?.links?.preview ?
                        <Img
                            src={`${val ? val?.preview?.url || val?.preview?.links?.preview : 0}`}
                            styles={gridStyles[name]?.ico?.styles || {
                                width: '28px',
                                height: '28px',
                                'border-radius': '34px',
                                overflow: 'hidden'
                            }}
                        />
                        :
                        svgSprite(val?.sort ? 'rankPlug' : 'rankNoRang',
                            gridStyles[name]?.ico?.styles || {
                                width: '34px',
                                height: '34px',
                                fill: rankColors[val?.sort]?.color
                            })
                    }
                </Div>
                {isOpen &&
                    ReactDOM.createPortal(<Div childrenRef={modal} styles={{
                        position: 'absolute', width: 0, overflow: 'hidden', transition: 'width .3s linear'
                    }}>
                        <Div childrenRef={modalInner} styles={{
                            position: 'absolute', top: 0, left: 0, display: 'flex'
                        }}>
                            <Div
                                styles={styles.rangSvgBox}
                            >
                                {val?.preview?.url || val?.preview?.links?.preview ?
                                    <Img
                                        src={val?.preview?.url || val?.preview?.links?.preview}
                                        styles={gridStyles[name]?.ico?.styles || {
                                            width: '28px',
                                            height: '28px',
                                            'border-radius': '34px',
                                            overflow: 'hidden'
                                        }}
                                    />
                                    : val.sort ?
                                        svgSprite('rankPlug', gridStyles[name]?.ico?.styles || {
                                            height: '28px',
                                            width: '28px',
                                            fill: rankColors[val?.sort]?.color
                                        })
                                        :
                                        <Div styles={{
                                            'border-radius': '28px', width: '28px', height: '28px', background: styles.variable.whiteColor
                                        }}>
                                            {svgSprite('rankNoRang', gridStyles[name]?.ico?.styles || {
                                                height: '28px',
                                                width: '28px'
                                            })}
                                        </Div>
                                }
                            </Div>
                            {
                                typeof val === 'string' ?
                                    <Div styles={{
                                        ...styles.rangName, ...leftSide ? styles.rangNameLeft : {}, background: rankColor.back, 'z-index': 1
                                    }}>
                                        {val || t(
                                            'grid-withoutQual', 'Без квалификации', 'widget', translation, 'grid'
                                        )}
                                        <Div styles={styles.borderRangName} />
                                    </Div>
                                    :
                                    <Div styles={{
                                        ...styles.rangName, ...leftSide ? styles.rangNameLeft : {}, background: rankColor.back, 'z-index': 1
                                    }}>
                                        {val?.name || t(
                                            'grid-withoutQual', 'Без квалификации', 'widget', translation, 'grid'
                                        )}
                                    </Div>

                            }
                        </Div>
                    </Div>
                    ,
                    document?.getElementById(`${gridName}portalDropdown`))
                }
            </Div>
        </Div>)
};

Rang.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string
};

export default Rang;